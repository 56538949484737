import axios from 'axios';

const instance = axios.create({ baseURL: process.env.REACT_APP_PANDO_API_URL });

const FetchPandoApi = async () => {
  if (localStorage.expires_time && localStorage.expires_time < new Date().getTime()) {
    //refresh the access token
    await refreshAccessToken();
  }

  setAuthHeader();

  return instance;
};

const setAuthHeader = function () {
  let accessToken = localStorage.access_token ? localStorage.access_token : null;
  let authorization = localStorage.token_type ? localStorage.token_type + ' ' + accessToken : null;
  instance.defaults.headers.common['Authorization'] = authorization;
};

const refreshAccessToken = function () {
  let refreshTokenObj = {};
  refreshTokenObj.refresh_token = localStorage.refresh_token;

  return instance.post('/auth/refresh-token', refreshTokenObj).then(function (response) {
    localStorage.token_type = response.data.token_type;
    localStorage.access_token = response.data.access_token;
    localStorage.expires_in = response.data.expires_in;
    localStorage.expires_time = new Date().getTime() + response.data.expires_in * 900;
  });
};

instance.interceptors.response.use(
  function (response) {
    console.log('FetchPandoApi:Response', response);
    return response;
  },
  function (error) {
    console.log('FetchPandoApi:Response:Error', error);
    //let history = useHistory();
    if (error.response) {
      if (error.response.status === 401) {
        if (error.response.data.code === 'NotAuthorizedException') {
          localStorage.loginAlert = 'NotAuthorizedException';
          logout();
          window.location.reload();
        }
      } else if (error.request) {
        // 요청이 이루어 졌으나 응답을 받지 못했습니다.
        // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
        // Node.js의 http.ClientRequest 인스턴스입니다.
        //addToast('응답이 없습니다. 잠시후 다시 시도해 주세요.', { appearance: 'error' ,autoDismiss : true})
      } else {
        // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
        //addToast('오류가 발생하였습니다.', { appearance: 'error' ,autoDismiss : true})
      }
    }
    return Promise.reject(error);
  }
);

const logout = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('userName');
  localStorage.removeItem('userId');
  localStorage.removeItem('ownerId');
  localStorage.removeItem('storeList');
  localStorage.removeItem('defaultStoreId');
  localStorage.removeItem('access_token');
  localStorage.removeItem('expires_time');
};

export default FetchPandoApi;

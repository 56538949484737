import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import '../scss/style.scss';
import '../css/contents.css';
import AuthRoute from '../auth/AuthRoute';
import Home from '../pages/Home';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('../components/TheLayout'));
const Login = React.lazy(() => import('../auth/Login'));

const App = () => {
  const [user] = useState(null);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    scrollToTop();
  }, []);

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
          <AuthRoute path="/home" render={(props) => <Home user={user} {...props} />} />
          <TheLayout />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
  //  }
};
export default App;

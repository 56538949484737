import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FetchPandoApi from '../common/FetchPandoApi';
import moment from 'moment';

class Home extends Component {
	constructor({ match }){
		super()
		this.state = {
			QuestionList: [],
			NoticeList: [],
			IsSuccess: false,
			loading: false,
			error: false
		}
	}

	componentDidMount() {
		this.QuestionCall();
		this.NoticeCall();
	}

	// 문의
	QuestionCall = async (e) => {
		try{
			const response = await FetchPandoApi()
			.then(function(f){
				return f.get('/board/question');
			})
			this.setState({ QuestionList : response.data.items });
			this.setState({ IsSuccess : true });
		} catch (e) {
			console.log("Error",e);
			this.setState({error: e});
			this.setState({ IsSuccess : false });
		}
		this.setState({loading: false});
		return true;
	}

	// 공지사항
	NoticeCall = async (e) => {
		try {
			const response = await FetchPandoApi()
			.then(function(f){
				return f.get('/board/notice');
			})
			this.setState({ NoticeList : response.data.items });
			this.setState({ IsSuccess : true });
		} catch (e) {
			console.log("Error",e);
			this.setState({error: e});
			this.setState({ IsSuccess : false });
		}
		this.setState({loading: false});
		return true;
	}

	render(){
		return (
			<div id="contents" className="main">
				<div className="location"> Home </div>
				<div className="row mb-50">
					<div className="main-box">
						<section className="secbox">
							<h2 className="tit04 mb-10"><b>어제 콜봇성과</b></h2>
							<div className="box bg01">
								<section className="secbox">
									<h3 className="tit04 mb-10">콜봇통화 요약</h3>
									<ul className="list03 col-03">
										<li>
											<dl className="dl07">
												<dt>결제금액</dt>
												<dd>
													<span className="number">2,000,000원</span>
													<span className="persent">지난주(목)대비 <em className="red">▲20%</em></span>
												</dd>
											</dl>
										</li>
										<li>
											<dl className="dl07">
												<dt>결제건수</dt>
												<dd>
													<span className="number">200건</span>
													<span className="persent">지난주(목)대비 <em className="red">▲20%</em></span>
												</dd>
											</dl>
										</li>
										<li>
											<dl className="dl07">
												<dt>평균통화시간</dt>
												<dd>
													<span className="number">50초</span>
													<span className="persent">지난주(목)대비 <em className="blue">▼20%</em></span>
												</dd>
											</dl>
										</li>
									</ul>
								</section>
								<section className="secbox mt-20">
									<h3 className="tit04 mb-10">상담원통화 요약</h3>
									<ul className="list03 col-03">
										<li>
											<dl className="dl07">
												<dt>결제금액</dt>
												<dd>
													<span className="number">2,000,000원</span>
													<span className="persent">지난주(목)대비 <em className="red">▲20%</em></span>
												</dd>
											</dl>
										</li>
										<li>
											<dl className="dl07">
												<dt>결제건수</dt>
												<dd>
													<span className="number">200건</span>
													<span className="persent">지난주(목)대비 <em className="red">▲20%</em></span>
												</dd>
											</dl>
										</li>
										<li>
											<dl className="dl07">
												<dt>평균통화시간</dt>
												<dd>
													<span className="number">50초</span>
													<span className="persent">지난주(목)대비 <em className="blue">▼20%</em></span>
												</dd>
											</dl>
										</li>
									</ul>
								</section>
								<section className="secbox mt-20">
									<h3 className="tit04 mb-10">콜봇통화+상담원통화 요약</h3>
									<ul className="list03 col-03">
										<li>
											<dl className="dl07">
												<dt>결제금액</dt>
												<dd>
													<span className="number">2,000,000원</span>
													<span className="persent">지난주(목)대비 <em className="red">▲20%</em></span>
												</dd>
											</dl>
										</li>
										<li>
											<dl className="dl07">
												<dt>결제건수</dt>
												<dd>
													<span className="number">200건</span>
													<span className="persent">지난주(목)대비 <em className="red">▲20%</em></span>
												</dd>
											</dl>
										</li>
										<li>
											<dl className="dl07">
												<dt>평균통화시간</dt>
												<dd>
													<span className="number">콜봇20초 +</span>
													<span className="number">상담원20초</span>
													<span className="persent">지난주(목)대비 <em className="blue">▼20%</em></span>
												</dd>
											</dl>
										</li>
									</ul>
								</section>
							</div>
						</section>
					</div>
					<div className="main-box">
						<section className="secbox">
							<h2 className="tit04 mb-10"><b>사장님웹 신규회원</b></h2>
							<div className="box">
								<ul className="list04 col-03">
									<li>
										<dl className="dl08">
											<dt>신규가입</dt>
											<dd>
												<span className="number">100명</span>
												<Link to='/StoreMemberList' className="btn type09 btn-small"><span>승인처리하기</span></Link>
											</dd>
										</dl>
									</li>
									<li>
										<dl className="dl08">
											<dt>승인처리진행중</dt>
											<dd>
												<span className="number">100명</span>
											</dd>
										</dl>
									</li>
									<li>
										<dl className="dl08">
											<dt>승인완료</dt>
											<dd>
												<span className="number">100명</span>
											</dd>
										</dl>
									</li>
								</ul>
							</div>
						</section>
						<section className="secbox mt-30">
							<h2 className="tit04 mb-10"><b>이번달 콜봇 과금</b></h2>
							<div className="box">
								<ul className="list04 col-02">
									<li>
										<dl className="dl08">
											<dt>과금 총 누적액</dt>
											<dd>
												<span className="number">1,000,000원</span>
											</dd>
										</dl>
									</li>
									<li>
										<dl className="dl08">
											<dt>과금 총 누적건수</dt>
											<dd>
												<span className="number">1,000,000건</span>
											</dd>
										</dl>
									</li>
								</ul>
							</div>
						</section>
						<section className="secbox mt-30">
							<h2 className="tit04 mb-10"><b>문의</b></h2>
							<div className="box">
								<ul className="list-noti">
									{this.state.QuestionList.map((q, i) =>
									i <= 7 ?
										<li key={i}>
											· <p className='ml-5'><Link to={`inquiryView/${q.questionId}`}><span className="txt08">{q.questionTitle}</span></Link></p>
											<span className="badge type02">{moment(q.createDate).format('YYYYMMDD') >= moment().day(-5).format('YYYYMMDD') ? 'NEW' : ''}</span>
											<span className="date">{moment(q.createDate).format('YYYY.MM.DD')}</span>
										</li> 
									:
										<></>
									)}	
								</ul>
							</div>
							<Link  to="/Inquiry" className="btn type05 btn-small absolute-r04"><span>&gt; 바로가기</span></Link>
						</section>
					</div>
				</div>
				<div className="row mb-50">
					<div className="main-box">
						<section className="secbox">
							<h2 className="tit04 mb-10"><b>어제 주문성과</b></h2>
							<div className="box bg01">
								<ul className="list03 col-03">
									<li>
										<dl className="dl07">
											<dt>취소건수</dt>
											<dd>
												<span className="number">자동취소 10건</span>
												<span className="number">취소 10건</span>
												<span className="persent">지난주(목)대비 <em className="red">▲20%</em></span>
											</dd>
										</dl>
									</li>
									<li>
										<dl className="dl07">
											<dt>동시유입최다콜수</dt>
											<dd>
												<span className="number">100건</span>
												<span className="persent">지난주(목)대비 <em className="red">▲20%</em></span>
											</dd>
										</dl>
									</li>
									<li>
										<dl className="dl07">
											<dt>1주문당 평균결제금액</dt>
											<dd>
												<span className="number">20,000원</span>
												<span className="persent">지난주(목)대비 <em className="blue">▼20%</em></span>
											</dd>
										</dl>
									</li>
								</ul>
							</div>
						</section>
					</div>
					<div className="main-box">
						<section className="secbox">
							<h2 className="tit04 mb-10"><b>공지</b></h2>
							<div className="box">
								<ul className="list-noti">
									{this.state.NoticeList.map((d, i) =>
									i <= 4 ?
									<li key={i}>
										· <p className='ml-5'><Link to={`NoticeView/${d.noticeId}`}><span className="txt08">{d.title}</span></Link></p>
										<span className="badge type02">{moment(d.createDate).format('YYYYMMDD') >= moment().day(-5).format('YYYYMMDD') ? 'NEW' : ''}</span>
										<span className="date">{moment(d.createDate).format('YYYY.MM.DD')}</span>
									</li>
									:
									<></>
									)}									
								</ul>
							</div>
							<Link to="/Notice" className="btn type05 btn-small absolute-r04"><span>&gt; 바로가기</span></Link>
						</section>
					</div>
				</div>
			</div>
		);
	};
};
export default Home;
import 'react-app-polyfill/ie11'; // For IE 9-11 support
import 'react-app-polyfill/stable';
import './Polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { icons } from './assets/icons'
import { Provider } from 'react-redux';
import store from './store';
import './index.css';
import Root from './pages/Root';
import * as serviceWorker from './ServiceWorker';
import axios from 'axios';
import { ToastProvider } from 'react-toast-notifications'

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '/' : 'http://pandobiz.com:8000/';

React.icons = icons

ReactDOM.render(
  <ToastProvider>
    <Provider store={store}>
      <Root/>
    </Provider>  
  </ToastProvider>
  ,
  document.getElementById('root')
);

serviceWorker.unregister();